import React       from 'react';
import { Text }    from '../../components/text/text';
import { Title }   from '../../components/title/title';
import { Wrapper } from '../../components/wrapper/wrapper.component';
import { cn }      from '../../util/bem';
import './authors-block.scss';

type PropsType = {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  inline?: boolean;
}
const DefaultProps = {};

const className = cn('author-block');

export const AuthorBlock = (props: PropsType) => (
    <Wrapper
        className={ className({ inline: props.inline }) }
        padding="md"
        valign="center"
        { ...props.inline ? { row: true, frame: 'solid-grey', margin: 'xl', style: { minWidth: '100%' } } : undefined }
        lg={ { direction: 'row', frame: 'solid-grey', fullWidth: true, margin: 'xl' } }
    >
        <Wrapper className={ className('image') } lg={ { direction: 'row' } } lastChildMargin>
            { props.children }
        </Wrapper>
        <Wrapper>
            <Title size="h5" uppercase>
                { props.title }
            </Title>
            <Text size="sm"><em>{ props.subtitle }</em></Text>
        </Wrapper>
    </Wrapper>
);

AuthorBlock.defaultProps = DefaultProps;
