import React                         from 'react';
import { MDXProvider }               from '@mdx-js/react';
import { ModalRoutingContext }       from 'gatsby-plugin-modal-routing';
import { Wrapper, WrapperPropsType } from 'lib/components/wrapper/wrapper.component';
import PropTypes                     from 'prop-types';
import { Divider }                   from '../../components/divider/divider';
import { Table }                     from '../../components/table/table';
import { Text, TextPropsType }       from '../../components/text/text';
import { Title, TitlePropsType }     from '../../components/title/title';
import { Navigation }                from '../../container/navigation/navigation';
import { cn }                        from '../../util/bem';
import { AuthorBlock }               from '../author-block/author-block';
import './post-layout.scss';

const components = {
    h1:          (props: TitlePropsType) => <Title size="h1" margin="xxl" { ...props } />,
    h2:          (props: TitlePropsType) => <Title size="h2" margin="md" { ...props } />,
    h3:          (props: TitlePropsType) => <Title size="h3" xsm={ { size: 'h4' } } margin="md" { ...props } />,
    h4:          (props: TitlePropsType) => <Title size="h4" margin="md" { ...props } />,
    p:           (props: TextPropsType) => <Text className={ cn('paragraph')() } margin="md" { ...props } />,
    blockquote:  (props: TitlePropsType) => <Title size="h3" xsm={ { size: 'h4' } } margin="md" { ...props } />,
    Table:       (props: TitlePropsType) => <Table { ...props } />,
    Wrapper:     (props: WrapperPropsType) => <Wrapper { ...props } />,
    AuthorBlock: (props: WrapperPropsType) => <AuthorBlock { ...props } />
};
const className = cn('post-layout');
// @ts-ignore
const PostLayout = (props) => (
    <ModalRoutingContext.Consumer>
        { ({ modal, closeTo }) => (
            <MDXProvider components={ components }>
                <Wrapper className={ className('annual-report') } padding="xxl" align="left">
                    { console.log(props.path) }
                    { console.log(props.path.slice(0, 20)) }
                    <Wrapper
                        maxWidth="800px"
                        styles={ { minHeight: '100vh', paddingTop: '10rem' } }
                        margin="xxl"
                        style={ { display: 'block' } }
                    >
                        <Title
                            size="h1"
                            margin="md"
                            maxWidth="600px"
                            sm={ { size: 'h2' } }
                            fullWidth
                        >
                            { props.pageContext.frontmatter.title }
                        </Title>
                        <Divider transparent margin="xxl" dashed />
                        <Wrapper>{ props.children }</Wrapper>
                    </Wrapper>

                    <Navigation
                        meta={ `annual report ${ props.path.slice(15, 19) }` }
                        isModal={ modal }
                        closeTo={ closeTo }
                        to={ props.path.slice(0, 20) || '/annual-report/2020' }
                    />
                </Wrapper>
            </MDXProvider>
        ) }
    </ModalRoutingContext.Consumer>
);
PostLayout.propTypes = { children: PropTypes.node.isRequired };

export default PostLayout;
