import React, { useState } from 'react';
import { Link }            from 'gatsby';
import { Wrapper }         from 'lib/components/wrapper/wrapper.component';
import { cn }              from 'lib/util/bem';
import { Divider }         from '../../components/divider/divider';
import { Text }            from '../../components/text/text';
import { getContent }      from '../../../pages/ink-stains/2020/data/get-content';
import { Burger, Close }   from './icons';
import './navigation.scss';

const className = cn('navigation');

type PropsType = {
  meta?: any;
  to?: string;
  isModal?: boolean;
  closeTo?: any;
};

export const Navigation = (props: PropsType) => (
    <Wrapper className={ className() }>

        <Wrapper row valign="center">
            <Link to={ props.to }>
                <Wrapper flex="1" row>
                    { ' ' }
                    <Burger />
                    <Divider transparent margin="sm" />
                    <Text uppercase size="xsm" noWrap>{ `PSI - ${ props.meta }` }</Text>
                </Wrapper>
            </Link>
            <Wrapper align="right" flex={ 1 }>
                { props.isModal && (
                    <Link to={ props.closeTo }>
                        <Wrapper align="center" valign="center" row>
                            <Text hide={ [ '', 'xsm' ] } size="xsm" uppercase margin="sm"> close</Text>
                            <Close />
                        </Wrapper>
                    </Link>
                ) }
            </Wrapper>
        </Wrapper>
    </Wrapper>
);
